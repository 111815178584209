import React, { MouseEvent } from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './DropdownBaseButtonTrigger.scss';
import CloseIcon from 'common/icons/CloseIcon';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import { useClickInterceptorProps } from 'common/components/ClickInterceptorLabel/hooks/use-click-interceptor-props';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { StyleGuideColorsEnum } from 'common/constants';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export type IconMetaT = {
    isEmpty: boolean;
    isFocus: boolean;
    isPressed: boolean;
    isDisabled: boolean;
    hasError: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    hasChanges: boolean;
    hasValue: boolean;
};

export type DropdownBaseButtonTriggerPropsT = {
    isEmpty?: boolean;
    isFocus?: boolean;
    isPressed?: boolean;
    isDisabled?: boolean;
    hasError?: boolean;
    hasWarning?: boolean;
    hasSuccess?: boolean;
    hasChanges?: boolean;

    onClick: (event: MouseEvent<HTMLDivElement>) => void;

    testSelector?: string;
    className?: string;

    renderLeftIcon?: (meta: IconMetaT) => React.ReactNode;
    renderRightIcon?: (meta: IconMetaT) => React.ReactNode;
    onRightIconClick?: (event: MouseEvent<HTMLDivElement>) => void;

    isShowClearControl?: boolean;
    onReset?: (event: MouseEvent<HTMLElement>) => void;
};

const DropdownBaseButtonTrigger: React.FC<DropdownBaseButtonTriggerPropsT> = React.memo((props) => {
    const {
        isEmpty,
        isFocus,
        isPressed,
        isDisabled,
        className,
        onClick,
        testSelector,
        hasError,
        hasWarning,
        hasSuccess,
        hasChanges,
        children,
        renderLeftIcon,
        renderRightIcon,
        onRightIconClick,
        isShowClearControl,
        onReset,
    } = props;

    const { t } = useTranslation();

    const rigthIconClickInterceptorsProps = useClickInterceptorProps({
        onClick: onRightIconClick,
    });

    const leftIconNode =
        renderLeftIcon &&
        renderLeftIcon({
            isEmpty: !!isEmpty,
            isFocus: !!isFocus,
            isPressed: !!isPressed,
            isDisabled: !!isDisabled,
            hasError: !!hasError,
            hasWarning: !!hasWarning,
            hasSuccess: !!hasSuccess,
            hasChanges: !!hasChanges,
            hasValue: !isEmpty,
        });

    const rightIconNode =
        renderRightIcon &&
        renderRightIcon({
            isEmpty: !!isEmpty,
            isFocus: !!isFocus,
            isPressed: !!isPressed,
            isDisabled: !!isDisabled,
            hasError: !!hasError,
            hasWarning: !!hasWarning,
            hasSuccess: !!hasSuccess,
            hasChanges: !!hasChanges,
            hasValue: !isEmpty,
        });

    return (
        <div
            className={cs(
                cx('trigger', {
                    'trigger--isEmpty': isEmpty,
                    'trigger--isFocus': isFocus,
                    'trigger--isPressed': isPressed,
                    'trigger--isDisabled': isDisabled,
                    'trigger--hasChanges': hasChanges,
                    'trigger--hasWarning': hasWarning,
                    'trigger--hasSuccess': hasSuccess,
                    'trigger--hasError': hasError,
                    'trigger--withLeftIcon': !!leftIconNode,
                }),
                className,
            )}
            onClick={onClick}
            data-test-selector={`${testSelector}_trigger`}
        >
            {!!leftIconNode && <div className={cx('trigger__icon--leftIcon')}>{leftIconNode}</div>}
            <div
                className={cx('trigger__label', {
                    'trigger__label--isEmpty': isEmpty,
                    'trigger__label--isDisabled': isDisabled,
                })}
            >
                {children}
            </div>
            {!!rightIconNode && (
                <div className={cx('trigger__icon', 'trigger__icon--rightIcon')} {...rigthIconClickInterceptorsProps}>
                    {rightIconNode}
                </div>
            )}
            {isShowClearControl && onReset && (
                <ClickInterceptorLabel className={cx('trigger__icon', 'trigger__icon--clear')}>
                    <Tooltip
                        position={TooltipPositionEnum.topCenter}
                        theme={TooltipThemeEnum.black}
                        tooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black}>
                                {t('common:tooltips.clear')}
                            </TooltipContent>
                        }
                    >
                        {() => (
                            <TransparentTrigger
                                spaces="xs"
                                onClick={(event) => {
                                    onReset(event);
                                }}
                                testSelector={`${testSelector}_reset`}
                                leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.gray} />}
                                reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                            />
                        )}
                    </Tooltip>
                </ClickInterceptorLabel>
            )}
            <div className={cx('trigger__icon')}>
                <ArrowsIcon />
            </div>
        </div>
    );
});

export default DropdownBaseButtonTrigger;
